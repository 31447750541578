<template>
  <div class="container-fluid py-3">
    <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_INCOME_VALUE') && $store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
          <div class="card-body" v-if="reportHeader">
            <h5 class="card-title">{{ reportHeader.client.company.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ reportHeader.client.company.shortcut }}</h6>
            <p class="card-text small">Wyświetlane dane z zakresu od {{ reportHeader.startDate }} do {{ reportHeader.endDate }}</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Początek okresu <span class="text-danger">*</span></label>
                <datetime v-model="dateFrom" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
              </div>
              <div class="col-md-6">
                <label>Koniec okresu <span class="text-danger">*</span></label>
                <datetime v-model="dateTo" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 align-self-end">
                <button class="btn btn-primary btn-sm" @click="generateReport()" :disabled="disabledBtn">Wyświetl raport</button>
              </div>
              <div class="col-md-6 align-self-end text-right">
                <button class="btn btn-warning btn-sm mr-2" @click="downloadReport('pdf')" :disabled="disabledBtn">Pobierz PDF</button>
                <button class="btn btn-success btn-sm" @click="downloadReport('xls')" :disabled="disabledBtn">Pobierz XLS</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-0 m-0 border-0" v-else>
          <div class="alert alert-warning alert--grouped">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br />Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive" v-if="reportData && !processing">
          <table class="table table-sm table-striped">
            <thead class="font-quick-medium">
              <tr>
                <th style="width:1%">Lp</th>
                <th>Okres</th>
                <th>Rozliczenie</th>
                <th class="text-right">Cena</th>
                <th class="text-right">Ilość</th>
                <th class="text-right">Wartość</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="reportData.length == 0">
                <td colspan="6" class="text-center">Brak danych do wyświetlenia</td>
              </tr>
              <template v-for="(positions, index1) in reportData">
                <tr :key="'A' + index1">
                  <td class="font-weight-bold">{{ index1 + 1 }}</td>
                  <td colspan="5" class="font-weight-bold">{{ positions.period }}</td>
                </tr>
                <template v-for="(position, index2) in positions.positions">
                  <tr :key="'B' + index1 + index2">
                    <td colspan="2"></td>
                    <td>{{ position.positionName }}</td>
                    <td class="text-right">{{ positionFormat(position.avgNetPrice) }} zł</td>
                    <td class="text-right">{{ position.sumQuantity }}</td>
                    <td class="text-right">{{ positionFormat(position.sumNetValue) }} zł </td>
                  </tr>
                </template>
                <tr :key="'C' + index1">
                  <td colspan="6" class="font-weight-bold text-right" style="background:#ccc">Suma {{ periodSum(positions.positions) }} zł</td>
                </tr>
              </template>
              <tr v-if="reportData">
                <td colspan="6" class="bg-secondary font-weight-bold text-right">Suma dla całości {{ totalSum }} zł</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="processing" class="loader my-3"></div>
      </div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
import { axios_instance as axios } from "@/axios-config.js";
import { Datetime } from "vue-datetime";
import moment from "moment";
import "vue-datetime/dist/vue-datetime.css";
export default {
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      reportHeader: false,
      reportData: false,
      dateFrom: null,
      dateTo: null,
      processing: false,
    };
  },
  created() {},
  methods: {
    async getReportValueIncome(startDate, endDate) {
      this.processing = true;
      const resource =
        "app/report/income?startDate=" +
        moment(startDate).format("YYYY-MM-DD") +
        "&endDate=" +
        moment(endDate).format("YYYY-MM-DD") +
        "&context=reportIncomeValue";
      try {
        this.$Progress.start();
        const { data } = await axios.get(resource);
        this.reportHeader = data["hydra:member"][0];
        this.reportData = data["hydra:member"][1];
        this.$Progress.finish();
        this.processing = false;
      } catch (error) {
        console.log(error);
        this.$Progress.fail();
        this.processing = false;
      }
    },
    downloadReport(filetype) {
      let startDate = moment(this.dateFrom).format("YYYY-MM-DD");
      let endDate = moment(this.dateTo).format("YYYY-MM-DD");

      let fileExtension = filetype == "pdf" ? "pdf" : "xlsx";
      let blobType =
        filetype == "pdf"
          ? "pdf"
          : "vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const resource =
        "app/report/income?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&type=" +
        filetype +
        "&context=reportIncomeValue";

      this.processing = true;
      this.$Progress.start();
      axios({
        url: resource,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/" + blobType })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "RMWP_" + startDate + "_" + endDate + "." + fileExtension
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.processing = false;
        this.$Progress.finish();
      });
    },
    periodSum(pos) {
      let periodSum = 0;
      pos.forEach((item) => {
        periodSum = periodSum + parseFloat(item["sumNetValue"]);
      });
      return periodSum.toLocaleString("pl-PL", { minimumFractionDigits: 2 });
    },
    positionFormat(pos) {
      let _pos = parseFloat(pos);
      return _pos.toLocaleString("pl-PL", { minimumFractionDigits: 2 });
    },
    generateReport() {
      this.reportHeader = null;
      this.reportData = null;
      this.getReportValueIncome(this.dateFrom, this.dateTo);
    },
  },
  computed: {
    totalSum() {
      let totalSum = 0;
      Object.entries(this.reportData).forEach(([key, val]) => {
        console.log(key);
        val["positions"].forEach((item) => {
          totalSum = totalSum + parseFloat(item["sumNetValue"]);
        });
      });
      return totalSum.toLocaleString("pl-PL", { minimumFractionDigits: 2 });
    },
    disabledBtn() {
      if (this.dateFrom && this.dateTo) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
